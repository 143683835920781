import { IconButton, Link, Tooltip, Typography, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  DraftScenarioStatus,
  DraftTypeEnum,
  useGetUserByIdQuery,
} from "../../../graphql/generated/graphql";
import { useRouter } from "next/router";
import { dollarFormat } from "../shared/dollarUtils";
import { DiscardDraftScenarioModal } from "./DiscardDraftScenarioModal";
import { useIntercom } from "react-use-intercom";
import {
  VIEWED_COMPLETED_SCENARIO,
  VIEWED_SAVED_SCENARIO,
} from "../shared/intercomEvents";

dayjs.extend(utc);
export interface MyDraftsRow {
  draftId: string;
  name?: string | null;
  status?: string | null;
  type?: string | null;
  createdOn?: string | null;
  createdBy?: string | null;
  RV?: number | null | undefined;
  rvDifference?: number | null | undefined;
  lastUpdated?: string | null;
}

export const getMyDraftsColumns = (): GridColDef<MyDraftsRow>[] => {
  const columns: GridColDef<MyDraftsRow>[] = [
    {
      field: "name",
      headerName: "Scenario",
      headerClassName: "column-header",

      flex: 1,
      renderCell: (params) => {
        return (
          <ViewDraftCell
            draftId={params.row.draftId}
            type={params.row.type}
            text={params.row.name}
            status={params.row.status}
          />
        );
      },
      valueGetter: (params) => params.row.name,
    },
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      flex: 1,
      headerClassName: "column-header",
      renderCell: (params) => {
        const formattedDate = dayjs(params.row.lastUpdated).format(
          "MM-DD-YYYY, h:mma"
        );
        return <Value value={formattedDate} />;
      },
    },
    {
      field: "Roster Value",
      headerName: "Roster Value (RVA)",
      headerClassName: "column-header",
      flex: 1,
      renderCell: (params) => {
        if (!params.row.RV) return "--";

        return (
          <>
            <Value value={dollarFormat(params.row.RV)} />
            &nbsp;
            <StyledDeltaValue value={params.row.rvDifference} />
          </>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      headerClassName: "column-header",
      flex: 1,
      renderCell: (params) => {
        return <DraftStatus value={params.row.status} />;
      },
      valueGetter: (params) => params.row.status,
    },

    {
      field: "createdBy",
      headerName: "Created By",
      width: 180,
      headerClassName: "column-header",
      flex: 1,
      renderCell: (params) => {
        return <CreatedByCell userId={params.row.createdBy} />;
      },
      valueGetter: (params) => params.row.createdBy,
    },

    {
      field: "viewDraft",
      headerName: "Actions",
      headerClassName: "column-header",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <ViewDraftCell
              icon
              draftId={params.row.draftId}
              type={params.row.type}
              status={params.row.status}
            />
            <DeleteDraftCell
              draftId={params.row.draftId}
              status={params.row.status}
            />
          </>
        );
      },
    },
  ];

  return columns;
};

export const StyledDeltaValue = (props: {
  value?: number | null;
  bold?: boolean;
  color?: string | undefined;
}) => {
  const value = props.value || 0;
  const rvaPrefix = value > 0 ? "+" : "";
  const colors = useTheme().palette;
  const rvaColor =
    Math.round(value) == 0
      ? colors.grey[600]
      : value > 0
      ? colors.success.dark
      : colors.error.main;

  return (
    <Value value={`(${rvaPrefix}${dollarFormat(value)})`} color={rvaColor} />
  );
};

export const Value = (props: {
  value?: number | string | null;
  bold?: boolean;
  color?: string | undefined;
}) => {
  const theme = useTheme();
  const colors = theme.palette;
  const color = props.color || colors.grey[600];

  return (
    <Typography fontSize={14} fontWeight={props.bold ? 500 : 400} color={color}>
      {props.value ?? "-"}
    </Typography>
  );
};

export const DraftStatus = (props: { value?: string | null }) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Typography
      fontSize={14}
      fontWeight={500}
      color={
        props.value === DraftScenarioStatus.ACTIVE
          ? colors.success.dark
          : colors.grey[600]
      }
    >
      {props.value
        ? props.value.charAt(0).toUpperCase() +
          props.value.slice(1).toLowerCase()
        : "-"}
    </Typography>
  );
};

export const CreatedByCell = (props: { userId?: string | null }) => {
  const theme = useTheme();
  const colors = theme.palette;

  const { data, error } = useGetUserByIdQuery({
    variables: {
      id: props.userId ?? "no-id",
    },
  });

  if (!data || error) return null;

  return (
    <Typography fontSize={14} fontWeight={400} color={colors.grey[600]}>
      {data.userById?.firstName} {data.userById?.lastName}
    </Typography>
  );
};

const ViewDraftCell = (props: {
  draftId?: string | null;
  type?: string | null;
  icon?: boolean;
  text?: number | string | null;
  tooltip?: string | null;
  status?: string | null;
}) => {
  const { trackEvent } = useIntercom();
  const router = useRouter();

  if (!props.draftId) return null;

  const isSim = props.type === DraftTypeEnum.SIMULATION;

  const handleClick = () => {
    if (props.status === DraftScenarioStatus.COMPLETE) {
      trackEvent(VIEWED_COMPLETED_SCENARIO);
    } else if (props.status === DraftScenarioStatus.ACTIVE) {
      trackEvent(VIEWED_SAVED_SCENARIO);
    }

    if (isSim) {
      router.push(`/draft/${props.draftId}?sim=true`);
    } else {
      router.push(`/draft/${props.draftId}`);
    }
  };

  if (props.icon) {
    return (
      <Tooltip title="View">
        <IconButton onClick={handleClick}>
          <ExitToAppIcon
            sx={{
              transform: "scale(1.1)",
              color: "#0085CA",
            }}
          />
        </IconButton>
      </Tooltip>
    );
  } else {
    return (
      <Link
        onClick={handleClick}
        underline="none"
        sx={{
          "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
          },
        }}
      >
        <Typography fontSize={14} fontWeight={500} color={"#0085CA"}>
          {props.text ?? "-"}
        </Typography>
      </Link>
    );
  }
};

const DeleteDraftCell = (props: {
  draftId?: string | null;
  status?: string | null;
}) => {
  if (!props.draftId) return null;

  return (
    <>
      <DiscardDraftScenarioModal draftId={props.draftId ?? "no-id"} />
    </>
  );
};
