import { Box, Stack, Typography, useTheme } from "@mui/material";
import Image from "next/image";
import RefereeMissingPermissions from "../../../public/Ref_missing_perms.svg";

export const UnauthorizedContent = (props: {
  width?: number;
  height?: number;
}) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Stack px="40px" mt={2} justifyContent="center" alignItems="center">
      <Box display="flex" justifyContent="center" alignContent="center">
        <Image
          src={RefereeMissingPermissions}
          objectFit="fill"
          alt={"Success"}
          width={props.width}
          height={props.height}
        />
      </Box>
      <Typography
        fontSize={28}
        fontWeight={600}
        color={colors.textColor.dark}
        mt={1}
        mb={1}
        fontFamily={"massilia-variable"}
      >
        Time Out! You don’t have access to view this page.
      </Typography>
      <Typography
        fontSize={16}
        color={colors.textColor.light}
        textAlign="center"
      >
        Please reach out to your club’s admin to request permissions.
      </Typography>
    </Stack>
  );
};
