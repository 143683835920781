export const shortDollars = (
  num?: number | null,
  places?: number | null,
  displaySuffix = true,
  nullValue = "N/A"
): string => {
  if (num === null || num === undefined) return nullValue;
  if (places === null || places === undefined) places = 1;

  const absNum = Math.abs(num);
  let dollars = "";
  const suffixM = displaySuffix ? "M" : "";
  const suffixK = displaySuffix ? "K" : "";
  if (absNum >= 1000000) {
    dollars = (absNum / 1000000).toFixed(places) + suffixM;
  } else if (absNum >= 1000) {
    dollars = (absNum / 1000).toFixed() + suffixK;
  } else {
    dollars = Math.floor(absNum).toString();
  }
  return (Math.round(num) < 0 ? "-$" : "$") + dollars;
};

// use '--' instead of 'N/A' for blank values
export const dollarFormat = (
  num?: number | null,
  places?: number | null
): string => {
  return shortDollars(num, places, true, "--");
};

export const toCurrency = (num?: number | null): string => {
  return (
    "$" +
    Math.round(num ?? 0)
      ?.toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
};
